import { useSignal } from "@preact/signals";

export default function RegisterForm({ changeForm, changeData }: { changeForm: (formName: string) => void, changeData: (l: string, p: string, c: string) => void }) {
    const login = useSignal("")
    const password = useSignal("")
    const repeatPassword = useSignal("")
    const errorReason = useSignal("")

    async function register() {
        if ((login.value && password.value && repeatPassword.value &&
            password.value == repeatPassword.value && password.value.length >= 6)) {
            const result = await fetch("/api/login", {
                method: "PUT",
                body: JSON.stringify({
                    login: login.value,
                    password: password.value,
                    repeatPassword: repeatPassword.value
                })
            })

            const data = await result.json()
            if (!data.success)
                errorReason.value = data.reason
            else {
                changeData(data.login, data.password, data.code)
                changeForm("checkCode")
            }

        }
    }

    return (
        <div class="d-flex flex-column mx-auto my-3 register w-[452px]">
            <h3 class="text-center mb-0 text-2xl font-semibold">Регистрация</h3>
            <div class="d-flex flex-column min-h-[500px] pb-3 shadow gap-[22px] mt-[27px] px-[12px]">
                <p class="text-center fw-medium fs-6 mt-3" style="color: #4a4a4a">Введите e-mail и пароль</p> 
                {errorReason.value &&
                    <div class="alert alert-danger">{errorReason.value}</div>}
                {login.value && !login.value.includes("@") &&
                    <div class="alert alert-danger rounded-0 mb-0">Логин должен быть адресом почты!</div>}
                {password.value && repeatPassword.value && password.value != repeatPassword.value &&
                    <div class="alert alert-danger rounded-0 mb-0">Пароли должны совпадать!</div>}
                {password.value && password.value.length < 6 &&
                    <div class="alert alert-danger rounded-0 mb-0">Минимальное количество символов в пароле - 6!</div>}

                <input type="email" key="login" name="username" required class="enter-form" placeholder="E-mail" value={login.value} onInput={e => login.value = e.currentTarget.value} />
                <input type="password" key="pass" required class="enter-form" placeholder="Пароль" value={password.value} onInput={e => password.value = e.currentTarget.value} />
                <input type="password" key="repeat" required class="enter-form" placeholder="Повторите пароль" value={repeatPassword.value} onInput={e => repeatPassword.value = e.currentTarget.value} />
                <div class="d-flex justify-content-end">
                    <button onClick={() => register()} class="btn-black">Зарегистрироваться</button>
                </div>
            </div>
        </div>

    )
}